import { merge } from 'lodash';
import * as React from 'react';

import { DynamicCreateClaimField } from '../../../../../common';
import { LabourRate } from '../types';
import { Brand } from './Brand';
import { Circumstances } from './Circumstances';
import { ClaimPolicy } from './ClaimPolicy';
import { ClaimRegion, RegionOption } from './ClaimRegion';
import { ClientClaimId } from './ClientClaimId';
import { CompanyName } from './CompanyName';
import { DateOfAccident } from './DateOfAccident';
import { DateOfAccidentNoPicker } from './DateOfAccidentNoPicker';
import { DateOfFirstRegistration } from './DateOfFirstRegistration';
import { DateOfInspection } from './DateOfInspection';
import { FieldType } from './FieldType';
import { LabourRateForAdditionalEstimate } from './LabourRateForAdditionalEstimate';
import { LabourRateForCashSettlement } from './LabourRateForCashSettlement';
import { Make } from './Make';
import { Mileage } from './Mileage';
import { Model } from './Model';
import { ModelSubtypeCode } from './ModelSubtypeCode';
import { ModelSubtypeCodeLong } from './ModelSubtypeCodeLong';
import NotifiedUsers, { NOTIFIED_USERS_DEFAULT_VALUE, NotifiedUser } from './NotifiedUsers';
import { PolicyMaxSpend } from './PolicyMaxSpend';
import { PolicyholderEmail } from './PolicyholderEmail';
import { PolicyholderFirstname } from './PolicyholderFirstname';
import { PolicyholderLastname } from './PolicyholderLastname';
import { PolicyholderName } from './PolicyholderName';
import { PolicyholderPhone } from './PolicyholderPhone';
import { Registration } from './Registration';
import { RegistrationNoLengthLimit } from './RegistrationNoLengthLimit';
import { RegistrationYearMonth } from './RegistrationYearMonth';
import { ShinkaAmount } from './ShinkaAmount';
import { Vin } from './Vin';
import { Year } from './Year';
import { fixDateFromDate, fixDateFromMoment } from './date';

const labourRatesArray: LabourRate[] = [];
// TODO: Add types!

interface FieldProps {
	brands: string[];
	regions: RegionOption[];
	clientName: string;
	currencySymbol: string;
	estimateType: string;
	// TODO: Type this correctly
	dateFormat: any;
	policies: any;
	isRequired?: boolean;
}

type Fields = {
	[key in FieldType]: {
		component: React.FC<FieldProps>;
		transformer: (value: any, dynamicCreateClaimFields: DynamicCreateClaimField[]) => { [index: string]: any };
	};
};

export const fields: Fields = {
	CLIENT_CLAIM_ID: {
		component: ClientClaimId,
		transformer: (values: any) => ({
			clientClaimId: values.clientClaimId.trim(),
		}),
	},
	POLICYHOLDER_NAME: {
		component: PolicyholderName,
		transformer: (values: any) => ({
			policyholderName: values.policyholderName.trim(),
		}),
	},
	POLICYHOLDER_FIRSTNAME: {
		component: PolicyholderFirstname,
		transformer: (values: any) => ({
			policyholderFirstname: (values.policyholderFirstname || '').trim(),
		}),
	},
	POLICYHOLDER_LASTNAME: {
		component: PolicyholderLastname,
		transformer: (values: any) => ({
			policyholderLastname: (values.policyholderLastname || '').trim(),
		}),
	},
	POLICYHOLDER_EMAIL: {
		component: PolicyholderEmail,
		transformer: (values: any) => ({
			policyholderEmail: (values.policyholderEmail || '').trim(),
		}),
	},
	POLICY_MAX_SPEND: {
		component: PolicyMaxSpend,
		transformer: (values: any) => ({
			policyMaxSpend: parseInt(values.policyMaxSpend.trim().replace(/\D/g, ''), 10),
		}),
	},
	SHINKA_AMOUNT: {
		component: ShinkaAmount,
		transformer: (values: any) => ({
			shinkaAmount: values?.shinkaAmount ? parseInt(values?.shinkaAmount?.trim().replace(/\D/g, ''), 10) : null,
		}),
	},
	COMPANY_NAME: {
		component: CompanyName,
		transformer: (values: any) => ({
			companyName: values.companyName ? values.companyName.trim() : '',
		}),
	},
	DATE_OF_ACCIDENT: {
		component: DateOfAccident,
		transformer: (values: any) => ({
			dateOfAccident: fixDateFromDate(values.dateOfAccident),
		}),
	},
	DATE_OF_INSPECTION: {
		component: DateOfInspection,
		transformer: (values: any) => ({
			dateOfAccident: fixDateFromDate(values.dateOfAccident),
		}),
	},
	DATE_OF_ACCIDENT_NO_PICKER: {
		component: DateOfAccidentNoPicker,
		transformer: (values: any) => ({
			dateOfAccident: fixDateFromMoment(values.dateOfAccident),
		}),
	},
	DATE_OF_FIRST_REGISTRATION: {
		component: DateOfFirstRegistration,
		transformer: (values: any) => ({
			vehicle: {
				dateOfFirstRegistration: fixDateFromMoment(values.dateOfFirstRegistration),
			},
		}),
	},
	REGISTRATION: {
		component: Registration,
		transformer: (values: any) => ({
			// estimating API requires the registration to be in the main body,
			// whereas flex API requires it to be in the vehicle object
			registration: (values.registration || '').trim().toUpperCase(),
			vehicle: {
				registration: (values.registration || '').trim().toUpperCase(),
			},
		}),
	},
	REGISTRATION_NO_LENGTH_LIMIT: {
		component: RegistrationNoLengthLimit,
		transformer: (values: any) => ({
			// estimating API requires the registration to be in the main body,
			// whereas flex API requires it to be in the vehicle object
			registration: values.registrationNoLengthLimit.trim().toUpperCase(),
			vehicle: {
				registration: values.registrationNoLengthLimit.trim().toUpperCase(),
			},
		}),
	},
	REGISTRATION_YEAR_MONTH: {
		component: RegistrationYearMonth,
		transformer: (values: any) => ({
			vehicle: {
				registrationYearMonth: values.registrationYearMonth.trim(),
			},
		}),
	},
	MODEL_SUBTYPE_CODE: {
		component: ModelSubtypeCode,
		transformer: (values: any) => ({
			vehicle: {
				modelSubtypeCode: values.modelSubtypeCode.trim().toUpperCase(),
			},
		}),
	},
	MODEL_SUBTYPE_CODE_LONG: {
		component: ModelSubtypeCodeLong,
		transformer: (values: any) => ({
			vehicle: {
				modelSubtypeCode: values.modelSubtypeCodeLong.trim().toUpperCase(),
			},
		}),
	},
	VIN: {
		component: Vin,
		transformer: (values: any) => ({
			vehicle: { vin: values.vin.trim().toUpperCase() },
		}),
	},
	MAKE: {
		component: Make,
		transformer: (values: any) => ({
			vehicle: { make: values.make.trim() },
		}),
	},
	MODEL: {
		component: Model,
		transformer: (values: any) => ({
			vehicle: { model: values.model.trim() },
		}),
	},
	YEAR: {
		component: Year,
		transformer: (values: any) => ({
			vehicle: { year: parseInt(values.year.trim(), 10) },
		}),
	},
	BRAND: {
		component: Brand,
		transformer: (values: any) => ({
			brand: values.brand.toLowerCase().replace(/\s/g, ''),
		}),
	},
	MILEAGE: {
		component: Mileage,
		transformer: (values: any) => ({
			mileage: parseInt(values.mileage.trim(), 10),
		}),
	},
	NOTIFIED_USERS: {
		component: NotifiedUsers,
		transformer: (values: { notifiedUsers: NotifiedUser[] }) => ({
			notifiedUserIds: (values.notifiedUsers || NOTIFIED_USERS_DEFAULT_VALUE).map(({ id }) => id),
		}),
	},
	CIRCUMSTANCES: {
		component: Circumstances,
		transformer: (values: any) => ({ circumstances: values.circumstances }),
	},
	CLAIM_POLICY: {
		component: ClaimPolicy,
		transformer: (values: any) => ({ policy: values.policy.toLowerCase() }),
	},
	CLAIM_REGION: {
		component: ClaimRegion,
		transformer: (values: any) => ({ region: values.region }),
	},
	LABOUR_RATE_FOR_CASH_SETTLEMENT: {
		component: LabourRateForCashSettlement,
		transformer: (values: any, dynamicCreateClaimFields: DynamicCreateClaimField[]) => ({
			labourRates: getLabourRatesForField(
				dynamicCreateClaimFields,
				'LABOUR_RATE_FOR_CASH_SETTLEMENT',
				values.labourRateForCashSettlement
			),
		}),
	},
	LABOUR_RATE_FOR_ADDITIONAL_ESTIMATE: {
		component: LabourRateForAdditionalEstimate,
		transformer: (values: any, dynamicCreateClaimFields: DynamicCreateClaimField[]) => ({
			labourRates: getLabourRatesForField(
				dynamicCreateClaimFields,
				'LABOUR_RATE_FOR_ADDITIONAL_ESTIMATE',
				values.labourRateForAdditionalEstimate
			),
		}),
	},
	[FieldType.PH_PHONE]: {
		component: PolicyholderPhone,
		transformer: (values: any) => ({
			phone: !values.policyholderPhone ? null : values.policyholderPhone.trim(),
		}),
	},
};

function getLabourRatesForField(
	dynamicCreateClaimFields: DynamicCreateClaimField[],
	field: string,
	labourRateValue: any
) {
	labourRatesArray.push({
		estimateType: dynamicCreateClaimFields.find((f) => f.field === field).estimateType,
		value: parseInt(labourRateValue.trim(), 10),
	});
	return labourRatesArray;
}

export function transform(values: any, fieldsToUse: FieldType[], dynamicCreateClaimFields: DynamicCreateClaimField[]) {
	return {
		...fieldsToUse.reduce((acc: any, value: FieldType) => {
			const transformed = fields[value].transformer(values, dynamicCreateClaimFields);
			return merge(acc, transformed);
		}, {}),
	};
}

export {
	ClientClaimId,
	DateOfAccident,
	DateOfFirstRegistration,
	Registration,
	Vin,
	Mileage,
	Circumstances,
	ModelSubtypeCode,
	ModelSubtypeCodeLong,
	ClaimPolicy,
	ClaimRegion,
	LabourRateForCashSettlement,
	LabourRateForAdditionalEstimate,
};
