import { InputGroup, Intent } from '@blueprintjs/core';
import * as React from 'react';
import { Field } from 'react-final-form';

import useClientTranslations from '../../../../hooks/useClientTranslations';
import { isEmailValid } from '../utils';
import { TractableFormGroup } from './Shared';

const PolicyholderEmail: React.FC<{ isRequired?: boolean }> = ({ isRequired = true }) => {
	const { t } = useClientTranslations();
	const required = (field: string, t: any, isRequired: boolean) => (value: any) => {
		if (isRequired && !value) {
			return t('{{field}} is required', { field });
		}

		if (value && !isEmailValid(value)) {
			t('Should be a valid email');
		}

		return undefined;
	};

	return (
		<Field name="policyholderEmail" validate={required(t('Policyholder Email'), t, isRequired)}>
			{({ input, meta }) => (
				<TractableFormGroup
					label={t('Policyholder Email')}
					inline
					intent={Intent.DANGER}
					helperText={
						meta.invalid && meta.touched && (t(meta.error) || (!meta.dirtySinceLastSubmit && t(meta.submitError)))
					}
				>
					<InputGroup {...input} placeholder="" />
				</TractableFormGroup>
			)}
		</Field>
	);
};

export { PolicyholderEmail };
