import * as React from 'react';

import { Box } from '../../../shared/Box';
import { DigitalJourneyView } from '../DigitalJoruney/DigitalJourneyView';
import { EstimateView } from './EstimateView';
import { NoEstimate } from './NoEstimate';

export const EstimateViewWrapper = ({ claim, update, isEditing, estimateIsAvailable, config, estimateStatus }: any) => {
	const { showPdfView = false, showEstimate = false, showPOI = false } = config.ui;

	// It indicates if the estimate table view should be shown
	const showEstimateTableView = (showEstimate || showPOI) && !showPdfView;

	return (
		showEstimateTableView && (
			<Box
				orientation="vertical"
				borderRight="1px solid #D7E3EB"
				p="16px 16px 0 0"
				gap={16}
				width="50%"
				minWidth="450px"
				maxWidth="750px"
			>
				{config.ui?.showDigitalJourneyData && <DigitalJourneyView />}
				{estimateIsAvailable ? (
					<EstimateView
						showEstimate={showEstimate}
						showPOI={showPOI}
						claim={claim}
						update={update}
						isEditing={isEditing}
					/>
				) : (
					<NoEstimate status={estimateStatus} />
				)}
			</Box>
		)
	);
};
