import { groupBy } from 'lodash';

import { EstimateType } from '@tractable/estimating-local-constants';
import { isAdditionalCostOperation, isAdditionalHoursOperation } from '@tractable/estimating-local-estimate-parsing';

import { AuxPart, ClaimResolvers, EstimatePart, IEstimatePart } from '../../../../generated/graphql';
import { Context } from '../../Context';

export enum RowOperationType {
	'HOURS' = 'hours',
	'COST' = 'cost',
	'OTHER' = 'other',
}

const filterInvalidParts = (parts: IEstimatePart[]): EstimatePart[] => parts.filter((x) => x.operation !== 'NONE');

const keepAuxParts = (parts: IEstimatePart[]): AuxPart[] => parts.filter((x) => x.partCategory === 'AUX');

const keepMainParts = (parts: IEstimatePart[]): EstimatePart[] => parts.filter((x) => x.partCategory === 'MAIN');

const partsWithGroupedAux = (parts: EstimatePart[], auxParts: AuxPart[]): EstimatePart[] => {
	const groupedByMain = groupBy(auxParts, (p) => p.belongsTo);
	const mainPanels = parts.map((p) => p.name);
	const orphanedAuxPanels = Object.keys(groupedByMain).filter((name) => !mainPanels.includes(name));
	const transformedParts = parts.map((p: EstimatePart) => ({
		...p,
		auxParts: groupedByMain[p.name],
	}));
	orphanedAuxPanels
		.flatMap((name) => groupedByMain[name])
		.forEach((auxPart) => {
			transformedParts.push({
				...auxPart,
				auxParts: [],
				__typename: 'EstimatePart',
			});
		});
	return transformedParts;
};

const estimateDetails: ClaimResolvers<Context>['estimate'] = async (claim, _, { dataSources }) => {
	//HACK: flexible API needs the estimate type, but it doesn't differentiate between SIMPLIFIED and DETAILED
	const estimateType = EstimateType.DETAILED;

	let estimate = null;

	try {
		estimate = claim.estimate
			? await dataSources.flexibleApi.getLatestEstimate({ claimId: claim.id, estimateType })
			: null;
	} catch (error) {
		// Using the logger throws an error with webpack
		console.error(`Error GET Latest Estimate for claim ${claim.id}`, { error });
	}

	const parts = estimate?.parts ?? [];

	return {
		info: {},
		paint: {},
		additionalCosts: [],
		additionalHours: [],
		adjustments: [],
		...estimate,
		operations:
			estimate?.operations.map((operation) => {
				let rowType = RowOperationType.OTHER;
				if (isAdditionalCostOperation(operation)) {
					rowType = RowOperationType.COST;
				} else if (isAdditionalHoursOperation(operation)) {
					rowType = RowOperationType.HOURS;
				}

				return {
					...operation,
					rowType,
				};
			}) ?? [],
		parts:
			estimate?.info?.estimateType === 'SIMPLIFIED'
				? partsWithGroupedAux(keepMainParts(parts), keepAuxParts(parts))
				: filterInvalidParts(parts),
		id: `${claim.id}:estimate`,
	};
};

export { estimateDetails };
