import { useClientConfigCtx } from './hooks/useClientConfigCtx';

export const useLocalisedDate = () => {
	const { config } = useClientConfigCtx();
	const date = {
		symbol: config.locale.symbol,
		options: config.locale.options.date,
	};

	const formatDate = (d: string, ignoreTimeZone = false): string => {
		if (ignoreTimeZone) {
			if (date && !date.options) date.options = {};
			date.options.timeZone = 'GMT';
		}

		return d && new Intl.DateTimeFormat(date.symbol, date.options || {}).format(new Date(d));
	};

	const formatDataYearMonth = (y: number, m: number): string => {
		const intl = Intl.DateTimeFormat(date.symbol, date.options || {});

		const dateToFormat = Date.UTC(y, m - 1);
		return intl
			.formatToParts(dateToFormat)
			.slice(0, 4)
			.map((a) => a.value)
			.join('');
	};

	const formatHourAndMinutes = (d: string): string => {
		return d && new Date(d).toISOString().substring(11, 16);
	};

	return {
		date,
		formatDate,
		formatDataYearMonth,
		formatHourAndMinutes,
		locale: config.locale,
	};
};
