import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { logEvent } from '@tractable/estimating-local-amplitude-logging';

import { useClientConfigCtx } from '../../hooks/useClientConfigCtx';
import useClientTranslations from '../../hooks/useClientTranslations';
import { Header, PageLayout } from '../shared';
import { DelightedSurveyFeedback } from '../shared/DelightedSurveyFeedback/DelightedSurveyFeedback';
import { NavIcon } from '../shared/Navbar';
import { Status } from './Status';
import { Table } from './Table';

const Hub: React.FC = () => {
	logEvent('Opened Portal');
	const navigate = useNavigate();
	const { t } = useClientTranslations();
	const { config } = useClientConfigCtx();
	const showDelightedSurvey = !!config.ui?.showDelightedSurvey;

	return (
		<PageLayout
			navigationIcon={NavIcon.TRLOGO}
			navigationHeader={t('Tractable Auto Estimator')}
			navigationOnClick={() => navigate('/')}
			header={() => (
				<Header
					title={t('Estimate overview')}
					sections={[
						config.ui?.showDigitalJourneyButton
							? [
									<Link
										data-test-id="requestNewDigitalJourneyClaim"
										key="new"
										className="bp3-button bp3-intent-primary"
										style={{ backgroundColor: '#3B25C4' }}
										type="button"
										to="/claim/digital-journey/new"
									>
										{t('Create new Digital Journey Claim')}
									</Link>,
							  ]
							: [],
						[
							config.ui?.hideCreateClaim || (
								<Link
									data-test-id="requestNewEstimate"
									key="new"
									className="bp3-button bp3-intent-primary"
									style={{ backgroundColor: '#3B25C4' }}
									type="button"
									to="/claim/new"
								>
									{t('Request new estimate')}
								</Link>
							),
						],
					]}
				/>
			)}
		>
			<Status />
			<Table />
			{showDelightedSurvey && <DelightedSurveyFeedback language={config.locale.symbol} clientId={config.clientId} />}
		</PageLayout>
	);
};

export { Hub };
