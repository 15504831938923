import { ApolloProvider } from '@apollo/client';
import { MantineProvider } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import * as React from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { PrivateClientConfig } from '../../common';
// TODO: Remove
import { ClientConfigProvider } from '../hooks/useClientConfigCtx';
import { useMantineThemeOverride } from '../hooks/useMantineThemeOverride';
import { useStyledComponentsThemeOverride } from '../hooks/useStyledComponentsThemeOverride';
import { Create, CreateDigitalJourney } from './Claim';
import { Edit } from './Edit';
import { Hub } from './Hub';
import { Review } from './Review';
import { Summary } from './Summary';
import { client } from './graphql/config';
import { PDFView } from './shared/PDFView';
import { GlobalStyle } from './styles';

interface Props {
	privateConfig?: PrivateClientConfig;
}

const App = ({ privateConfig }: Props) => {
	const { mantineThemeOverride, getStylesOverride } = useMantineThemeOverride();
	const { styledComponentsThemeOverride } = useStyledComponentsThemeOverride();

	return (
		<ApolloProvider client={client({ uri: '/api/graphql' })}>
			<GlobalStyle />
			<MantineProvider theme={mantineThemeOverride} styles={getStylesOverride()} withNormalizeCSS>
				<NotificationsProvider limit={1} position="bottom-center" containerWidth={568}>
					<ThemeProvider theme={styledComponentsThemeOverride}>
						<ClientConfigProvider privateConfig={privateConfig}>
							<Router>
								<Routes>
									<Route path="/hub" Component={Hub} />
									<Route path="/claim/new" Component={Create} />
									<Route path="/claim/digital-journey/new" Component={CreateDigitalJourney} />
									<Route path="/summary/:id" Component={Summary} />
									<Route path="/finished/:id" Component={Review} />
									<Route path="/edit/:id" Component={Edit} />
									<Route path="/pdf/:id" Component={PDFView} />
									<Route path="*" element={<Navigate to="/hub" />} />
								</Routes>
							</Router>
						</ClientConfigProvider>
					</ThemeProvider>
				</NotificationsProvider>
			</MantineProvider>
		</ApolloProvider>
	);
};

export { App };
