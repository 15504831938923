import { InputGroup, Intent } from '@blueprintjs/core';
import * as React from 'react';
import { Field } from 'react-final-form';

import useClientTranslations from '../../../../hooks/useClientTranslations';
import { TractableFormGroup } from './Shared';

export const required =
	(field: string, t: any, isRequired = true) =>
	(value: any) =>
		value || !isRequired ? undefined : t(`{{field}} is required`, { field });

const PolicyholderFirstname: React.FC<{ isRequired?: boolean }> = ({ isRequired = true }) => {
	const { t } = useClientTranslations();

	return (
		<Field name="policyholderFirstname" validate={required(t('Policyholder Firstname'), t, isRequired)}>
			{({ input, meta }) => (
				<TractableFormGroup
					label={t('Policyholder Firstname')}
					inline
					intent={Intent.DANGER}
					helperText={
						meta.invalid && meta.touched && (t(meta.error) || (!meta.dirtySinceLastSubmit && t(meta.submitError)))
					}
				>
					<InputGroup {...input} placeholder="" />
				</TractableFormGroup>
			)}
		</Field>
	);
};

export { PolicyholderFirstname };
