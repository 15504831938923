import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { useLocalisedDate } from '../../../../utils';
import { Box } from '../../../shared/Box';
import { Text } from '../../../shared/Text';
import { djSessionMock } from './DigitalJourneyTypes';

export const DigitalJourneyView: React.FC = () => {
	const { t } = useTranslation('EstimatingPortal');
	const { formatDate, formatHourAndMinutes } = useLocalisedDate();

	return (
		<Box p="24px 24px 24px 24px" m="0" orientation="vertical" gap={8}>
			<DigitalJourneyInfoLine
				title={t('Completed at date')}
				value={djSessionMock.completedAt ? formatDate(djSessionMock.completedAt) : ''}
			/>
			<DigitalJourneyInfoLine
				title={t('Registration number')}
				value={djSessionMock.digitalJourney.licensePlatePh ?? ''}
			/>
			<DigitalJourneyInfoLine
				title={t('Vehicle type')}
				value={
					djSessionMock.digitalJourney.vehicleTypePh
						? t(`Vehicle ${djSessionMock.digitalJourney.vehicleTypePh.toLowerCase()}`)
						: ''
				}
			/>
			<DigitalJourneyInfoLine
				title={t('Date of accident')}
				value={
					djSessionMock.digitalJourney?.accidentDateTime
						? formatDate(djSessionMock.digitalJourney.accidentDateTime)
						: ''
				}
			/>
			<DigitalJourneyInfoLine
				title={t('Time of accident')}
				value={
					djSessionMock.digitalJourney.accidentDateTime
						? formatHourAndMinutes(djSessionMock.digitalJourney.accidentDateTime)
						: ''
				}
			/>
			<DigitalJourneyInfoLine
				title={t('Country of accident')}
				value={
					djSessionMock.digitalJourney?.accidentLocation?.country
						? t(djSessionMock.digitalJourney.accidentLocation.country)
						: ''
				}
			/>
			<DigitalJourneyInfoLine
				title={t('Province of accident')}
				value={
					djSessionMock.digitalJourney?.accidentLocation?.province
						? t(djSessionMock.digitalJourney.accidentLocation.province)
						: ''
				}
			/>
			<DigitalJourneyInfoLine
				title={t('City of accident')}
				value={djSessionMock?.digitalJourney?.accidentLocation.city ?? ''}
			/>
			<DigitalJourneyInfoLine
				title={t('Type of accident')}
				value={(djSessionMock.digitalJourney?.accidentType ?? '').toLowerCase()}
			/>
			<DigitalJourneyInfoLine
				title={t('No of vehicles involved')}
				value={
					djSessionMock.digitalJourney?.numVehiclesInvolved
						? t(`${djSessionMock.digitalJourney.numVehiclesInvolved} vehicles involved`)
						: ''
				}
			/>
			<DigitalJourneyInfoLine
				title={t('Presence of a driver in vehicle')}
				value={
					djSessionMock.digitalJourney &&
					'driverPresent' in djSessionMock.digitalJourney &&
					typeof djSessionMock.digitalJourney.driverPresent === 'boolean'
						? t(djSessionMock.digitalJourney.driverPresent ? 'Yes' : 'No')
						: ''
				}
			/>
			<DigitalJourneyInfoLine
				title={t('Information on other vehicle')}
				value={
					djSessionMock.digitalJourney &&
					'informationOnOtherVehicle' in djSessionMock.digitalJourney &&
					typeof djSessionMock.digitalJourney.informationOnOtherVehicle === 'boolean'
						? t(djSessionMock.digitalJourney.informationOnOtherVehicle ? 'Yes' : 'No')
						: ''
				}
			/>
			<DigitalJourneyInfoLine
				title={t('No of injured people')}
				value={
					djSessionMock.digitalJourney && !Number.isNaN(djSessionMock.digitalJourney.numInjuredPeople)
						? djSessionMock.digitalJourney.numInjuredPeople.toString()
						: ''
				}
			/>
			<DigitalJourneyInfoLine
				title={t('CAI')}
				value={djSessionMock.digitalJourney?.cai ? t(`CAI ${djSessionMock.digitalJourney.cai.toLowerCase()}`) : ''}
			/>
			<DigitalJourneyInfoLine
				title={t('Authorities intervention')}
				value={
					djSessionMock.digitalJourney?.authoritiesIntervention
						? t(`Authorities ${djSessionMock.digitalJourney.authoritiesIntervention.toLowerCase()}`)
						: ''
				}
			/>
			<DigitalJourneyInfoLine
				title={t('Insured vehicle circumstance')}
				value={
					djSessionMock.digitalJourney?.vehicleCircumstancePh
						? t(`Circumstance ${djSessionMock.digitalJourney.vehicleCircumstancePh.toLowerCase()}`)
						: ''
				}
			/>
			<DigitalJourneyInfoLine
				title={t('Other vehicle circumstance')}
				value={
					djSessionMock.digitalJourney?.otherVehicleCircumstance
						? t(`Circumstance ${djSessionMock.digitalJourney.otherVehicleCircumstance.toLowerCase()}`)
						: ''
				}
			/>
			<DigitalJourneyInfoLine
				title={t('Vehicle mileage')}
				value={
					djSessionMock.digitalJourney && !Number.isNaN(djSessionMock.digitalJourney.mileage)
						? djSessionMock.digitalJourney.mileage.toString()
						: ''
				}
			/>
			<DigitalJourneyInfoLine
				title={t('Airbag deployed')}
				value={
					djSessionMock.digitalJourney &&
					'airbagDeployed' in djSessionMock.digitalJourney &&
					typeof djSessionMock.digitalJourney.airbagDeployed === 'boolean'
						? t(djSessionMock.digitalJourney.airbagDeployed ? 'Yes' : 'No')
						: ''
				}
			/>
			<DigitalJourneyInfoLine
				title={t('Vehicle drivable')}
				value={
					djSessionMock.digitalJourney &&
					'isDrivable' in djSessionMock.digitalJourney &&
					typeof djSessionMock.digitalJourney.isDrivable === 'boolean'
						? t(djSessionMock.digitalJourney.isDrivable ? 'Yes' : 'No')
						: ''
				}
			/>
			<DigitalJourneyInfoLine
				title={t('Insured vehicle impact location')}
				value={
					djSessionMock.digitalJourney?.pointsOfImpactPh && djSessionMock.digitalJourney.pointsOfImpactPh.length > 0
						? djSessionMock.digitalJourney.pointsOfImpactPh
								.map((pointOfImpact) => t(`Impact ${pointOfImpact.toLowerCase()}`))
								.join(', ')
						: ''
				}
			/>
			<DigitalJourneyInfoLine
				title={t('Insured vehicle driver firstname')}
				value={djSessionMock.digitalJourney?.firstNamePh ?? ''}
			/>
			<DigitalJourneyInfoLine
				title={t('Insured vehicle driver lastname')}
				value={djSessionMock.digitalJourney?.lastNamePh ?? ''}
			/>
			<DigitalJourneyInfoLine
				title={t('Insured vehicle driver personal code')}
				value={djSessionMock.digitalJourney?.personalCodePh ?? ''}
			/>
			<DigitalJourneyInfoLine
				title={t('Other vehicle registration number')}
				value={djSessionMock.digitalJourney?.otherVehicleLicensePlate ?? ''}
			/>
			<DigitalJourneyInfoLine
				title={t('Other vehicle type')}
				value={
					djSessionMock.digitalJourney?.otherVehicleType
						? t(`Vehicle ${djSessionMock.digitalJourney.otherVehicleType.toLowerCase()}`)
						: ''
				}
			/>
			<DigitalJourneyInfoLine
				title={t('Other vehicle insurance')}
				value={djSessionMock.digitalJourney?.otherVehicleInsurance ?? ''}
			/>
			<DigitalJourneyInfoLine
				title={t('Other vehicle driver firstname')}
				value={djSessionMock.digitalJourney?.otherDriverFirstName ?? ''}
			/>
			<DigitalJourneyInfoLine
				title={t('Other vehicle driver lastname')}
				value={djSessionMock.digitalJourney?.otherDriverLastName ?? ''}
			/>
			<DigitalJourneyInfoLine
				title={t('Other vehicle driver personal code')}
				value={djSessionMock.digitalJourney?.otherDriverPersonalCode ?? ''}
			/>
			<DigitalJourneyInfoLine
				title={t('Other vehicle impact location')}
				value={
					djSessionMock.digitalJourney?.otherVehiclePointsOfImpact &&
					djSessionMock.digitalJourney.otherVehiclePointsOfImpact.length > 0
						? djSessionMock.digitalJourney.otherVehiclePointsOfImpact
								.map((pointOfImpact) => t(`Impact ${pointOfImpact.toLowerCase()}`))
								.join(', ')
						: ''
				}
			/>
			{new Array(5).fill(undefined).map((_, index) => (
				<>
					<DigitalJourneyInfoLine
						title={`${t('Injured person firstname')} ${index + 1}`}
						value={
							djSessionMock.digitalJourney?.injuredPeople && djSessionMock.digitalJourney.injuredPeople[index]
								? djSessionMock.digitalJourney.injuredPeople[index].firstName
								: ''
						}
					/>
					<DigitalJourneyInfoLine
						title={`${t('Injured person lastname')} ${index + 1}`}
						value={
							djSessionMock.digitalJourney?.injuredPeople && djSessionMock.digitalJourney.injuredPeople[index]
								? djSessionMock.digitalJourney.injuredPeople[index].lastName
								: ''
						}
					/>
					<DigitalJourneyInfoLine
						title={`${t('Injured person personal code')} ${index + 1}`}
						value={
							djSessionMock.digitalJourney?.injuredPeople &&
							djSessionMock.digitalJourney.injuredPeople[index] &&
							djSessionMock.digitalJourney?.injuredPeople[index].personalCode
								? djSessionMock.digitalJourney.injuredPeople[index].personalCode
								: ''
						}
					/>
					<DigitalJourneyInfoLine
						title={`${t('Injured person role')} ${index + 1}`}
						value={
							djSessionMock.digitalJourney?.injuredPeople &&
							djSessionMock.digitalJourney.injuredPeople[index] &&
							djSessionMock.digitalJourney.injuredPeople[index].role
								? t(djSessionMock.digitalJourney.injuredPeople[index].role.toLowerCase())
								: ''
						}
					/>
				</>
			))}
			<DigitalJourneyInfoLine
				title={t('Accident additional details')}
				value={djSessionMock.digitalJourney?.additionalDetails ?? ''}
			/>
			<DigitalJourneyInfoLine
				title={t('Claim handling decision')}
				value={
					djSessionMock.digitalJourney?.claimHandlingDecision
						? t(`Claim decision ${djSessionMock.digitalJourney.claimHandlingDecision.toLowerCase()}`)
						: ''
				}
			/>
			<DigitalJourneyInfoLine
				title={t('Insured vehicle driver postcode')}
				value={djSessionMock.digitalJourney?.postalCodePh ?? ''}
			/>
			<DigitalJourneyInfoLine
				title={t('Chosen bodyshop name')}
				value={djSessionMock.digitalJourney?.bodyShop?.name ?? ''}
			/>
			<DigitalJourneyInfoLine
				title={t('Chosen bodyshop address')}
				value={djSessionMock.digitalJourney?.bodyShop?.address ?? ''}
			/>
			<DigitalJourneyInfoLine
				title={t('Chosen bodyshop postcode')}
				value={djSessionMock.digitalJourney?.bodyShop?.postalCode ?? ''}
			/>
			<DigitalJourneyInfoLine
				title={t('Chosen bodyshop city')}
				value={djSessionMock.digitalJourney?.bodyShop?.city ?? ''}
			/>
			<DigitalJourneyInfoLine
				title={t('Chosen bodyshop VAT number')}
				value={djSessionMock.digitalJourney?.bodyShop?.vatNumber ?? ''}
			/>
			<DigitalJourneyInfoLine
				title={t('Vandalism severity')}
				value={
					djSessionMock.digitalJourney?.vandalismSeverity
						? t(`Severity ${djSessionMock.digitalJourney.vandalismSeverity.toLowerCase()}`)
						: ''
				}
			/>
			<DigitalJourneyInfoLine
				title={t('Damaged glass point of impact')}
				value={
					djSessionMock.digitalJourney?.pointsOfImpactGlass
						? djSessionMock.digitalJourney.pointsOfImpactGlass
								.map((pointOfImpactGlass) => t(`Glass impact ${pointOfImpactGlass.toLowerCase()}`))
								.join(', ')
						: ''
				}
			/>
			<DigitalJourneyInfoLine
				title={t('Event type')}
				value={
					djSessionMock.digitalJourney?.naturalEventType
						? t(`Event type ${djSessionMock.digitalJourney.naturalEventType.toLowerCase()}`)
						: ''
				}
			/>
			<DigitalJourneyInfoLine
				title={t('Warranty type')}
				value={
					djSessionMock.digitalJourney?.insuranceType
						? t(`Warranty type ${djSessionMock.digitalJourney.insuranceType.toLowerCase()}`)
						: ''
				}
			/>
		</Box>
	);
};

const DigitalJourneyInfoLine: React.FC<{ title: string; value: string }> = ({ title, value }) => {
	return (
		<Box orientation="horizontal" gap={24} justifyContent="space-between">
			<Text typography="small">{title}</Text>
			<Text typography="small">{value}</Text>
		</Box>
	);
};
