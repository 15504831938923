export interface GenericObject {
	[key: string]: any;
}

export interface QuestionInput {
	key: string;
	value: boolean;
}

export enum PointOfImpact {
	FRONT_CENTER = 'Front Center',
	LEFT_FRONT_CORNER = 'Left Front Corner',
	RIGHT_FRONT_CORNER = 'Right Front Corner',
	REAR_CENTER = 'Rear Center',
	LEFT_REAR_CORNER = 'Left Rear Corner',
	RIGHT_REAR_CORNER = 'Right Rear Corner',
	LEFT_SIDE = 'Left Side',
	RIGHT_SIDE = 'Right Side',
}

export interface CoaPhotosInput {
	format: string;
	path: string;
	key: string;
	bucket: string;
}

export interface CoaSessionInfo {
	id: string;
	claimId: string;
	expiredAt: string;
	clientId: string;
	startedAt: string;
	classifiedAt: string;
	completedAt: string;
	rating: number;
	vehiclePhotos: CoaPhotosInput[];
	damagePhotos: CoaPhotosInput[];
	additionalPhotos: CoaPhotosInput[];
	vehicleConditions: QuestionInput[];
	mileage: number;
	damagedParts: string[];
	pointsOfImpact: PointOfImpact[];
	vin: string;
	sessionConfig?: GenericObject;
	enablePayeeJourney?: boolean;
	payee?: {
		iban?: string;
		name?: string;
		ssn?: string;
		phone?: string;
	};
}

export type DigitalJourney = {
	// personal info
	firstNamePh: string;
	lastNamePh: string;
	phoneNumberPh: string;
	emailPh: string;
	licensePlatePh: string;

	// main accident info
	accidentDateTime?: string;
	accidentLocation?: DigitalJourneyAccidentLocation;
	accidentType?: AccidentType;
	vandalismSeverity?: VandalismSeverity;
	naturalEventType?: NaturalEventType;
	insuranceType?: InsuranceType;
	numInjuredPeople?: number;
	driverPresent?: boolean;
	informationOnOtherDriver?: boolean;
	additionalDetails?: string;
	numVehiclesInvolved?: number;
	informationOnOtherVehicle?: boolean;
	claimHandlingDecision?: ClaimHandlingDecision;
	bodyShop?: DigitalJourneyBodyShop;
	cai?: CaiEnum;
	authoritiesIntervention?: AuthoritiesIntervention;
	accidentResponsibility?: AccidentResponsibility;

	// photos
	caiPhoto?: CaiPhoto[];
	hospitalReportPhoto?: HospitalReportPhoto[];
	authoritiesPhoto?: AuthoritiesPhoto[];

	// vehicle info
	vehicleTypePh?: VehicleType;
	pointsOfImpactPh?: PointOfImpact[];
	mileage?: number;
	airbagDeployed?: boolean;
	isDrivable?: boolean;
	pointsOfImpactGlass?: PointOfImpactGlass[];
	vehicleCircumstancePh?: Circumstance;
	personalCodePh?: string;
	postalCodePh?: string;

	// other driver info
	otherDriverFirstName?: string;
	otherDriverLastName?: string;
	otherDriverPersonalCode?: string;
	otherVehicleInsurance?: string;
	otherVehicleLicensePlate?: string;
	otherVehiclePointsOfImpact?: PointOfImpact[];
	otherVehicleCircumstance?: Circumstance;
	otherVehicleType?: VehicleType;

	// injured people
	injuredPeople?: InjuredPerson[];
};
export type DigitalJourneyAccidentLocation = {
	city: string;
	postalCode?: string;
	province: string;
	country: string;
};

export type DigitalJourneyBodyShop = {
	name: string;
	region: string;
	province: string;
	city: string;
	address: string;
	postalCode: string;
	phoneNumber: string;
	email: string;
	vatNumber: string;
};

export type CaiPhoto = {
	format: string;
	path: string;
	bucket: string;
	key: string;
	clientImageId: string;
};
export type HospitalReportPhoto = {
	format: string;
	path: string;
	bucket: string;
	key: string;
	clientImageId: string;
};
export type AuthoritiesPhoto = {
	format: string;
	path: string;
	bucket: string;
	key: string;
	clientImageId: string;
};

export type InjuredPerson = {
	firstName: string;
	lastName: string;
	personalCode?: string;
	role: InjuredRole;
};

export enum AccidentType {
	COLLISION_BETWEEN_VEHICLES = 'COLLISION_BETWEEN_VEHICLES',
	THEFT_FIRE_VANDALISM = 'THEFT_FIRE_VANDALISM',
	GLASS = 'GLASS',
	NATURAL_EVENTS = 'NATURAL_EVENTS',
	KASKO = 'KASKO',
	OTHER = 'OTHER',
}

export enum VandalismSeverity {
	PARTIAL_THEFT = 'PARTIAL_THEFT',
	TOTAL_THEFT = 'TOTAL_THEFT',
	PARTIAL_FIRE = 'PARTIAL_FIRE',
	TOTAL_FIRE = 'TOTAL_FIRE',
	VANDALISM = 'VANDALISM',
}

export enum NaturalEventType {
	HAIL = 'HAIL',
	NATURAL_EVENT = 'NATURAL_EVENT',
}

export enum InsuranceType {
	KASKO = 'KASKO',
	COLLISION = 'COLLISION',
}

export enum CaiEnum {
	ONE_SIGNED = 'ONE_SIGNED',
	BOTH_SIGNED = 'BOTH_SIGNED',
	NOT_AVAILABLE = 'NOT_AVAILABLE',
}

export enum Circumstance {
	FAILURE_TO_OBSERVE_SIGNAL = 'FAILURE_TO_OBSERVE_SIGNAL',
	COMING_FROM_RIGHT = 'COMING_FROM_RIGHT',
	WRONG_WAY_ON_ROAD = 'WRONG_WAY_ON_ROAD',
	REVERSING = 'REVERSING',
	TURNING_LEFT = 'TURNING_LEFT',
	TURNING_RIGHT = 'TURNING_RIGHT',
	OVERTAKING = 'OVERTAKING',
	CHANGING_LANE = 'CHANGING_LANE',
	MOVING_IN_SAME_DIRECTION_DIFFERENT_LANE = 'MOVING_IN_SAME_DIRECTION_DIFFERENT_LANE',
	REAR_END_COLLISION = 'REAR_END_COLLISION',
	CIRCULATING_IN_ROUNDABOUT = 'CIRCULATING_IN_ROUNDABOUT',
	ENTERING_ROUNDABOUT = 'ENTERING_ROUNDABOUT',
	ENTERING_PRIVATE_AREA = 'ENTERING_PRIVATE_AREA',
	EXITING_PRIVATE_AREA = 'EXITING_PRIVATE_AREA',
	PARKING = 'PARKING',
	STARTING_AFTER_STOP = 'STARTING_AFTER_STOP',
	STATIONARY_VEHICLE = 'STATIONARY_VEHICLE',
	OTHER = 'OTHER',
}

export enum VehicleType {
	CAR = 'CAR',
	MOTORCYCLE = 'MOTORCYCLE',
}

export enum ClaimHandlingDecision {
	BODY_SHOP = 'BODY_SHOP',
	EXPERT = 'EXPERT',
	PHOTO_CAPTURE = 'PHOTO_CAPTURE',
}

export enum AuthoritiesIntervention {
	POLICE = 'POLICE',
	LOCAL_POLICE = 'LOCAL_POLICE',
	CITY_POLICE = 'CITY_POLICE',
	OTHER_POLICE = 'OTHER_POLICE',
	CARABINIERI = 'CARABINIERI',
	FIREFIGHTERS = 'FIREFIGHTERS',
	NO = 'NO',
}

export enum InjuredRole {
	DRIVER = 'DRIVER',
	PASSENGER = 'PASSENGER',
}

export enum PointOfImpactGlass {
	FRONT_WINDOW = 'FRONT_WINDOW',
	REAR_WINDOW = 'REAR_WINDOW',
	LEFT_FRONT_DEFLECTOR = 'LEFT_FRONT_DEFLECTOR',
	RIGHT_FRONT_DEFLECTOR = 'RIGHT_FRONT_DEFLECTOR',
	LEFT_REAR_DEFLECTOR = 'LEFT_REAR_DEFLECTOR',
	RIGHT_REAR_DEFLECTOR = 'RIGHT_REAR_DEFLECTOR',
	RIGHT_FRONT_WINDOW = 'RIGHT_FRONT_WINDOW',
	LEFT_FRONT_WINDOW = 'LEFT_FRONT_WINDOW',
	RIGHT_REAR_WINDOW = 'RIGHT_REAR_WINDOW',
	LEFT_REAR_WINDOW = 'LEFT_REAR_WINDOW',
}

export enum AdditionalPhotoType {
	CAI = 'CAI',
	AUTHORITIES = 'AUTHORITIES',
	INJURED = 'INJURED',
	VEHICLE_DAMAGE = 'VEHICLE_DAMAGE',
	VEHICLE_CORNERS = 'VEHICLE_CORNERS',
	OTHER = 'OTHER',
}

export enum AccidentResponsibility {
	T = 'T', // Total responsibility for Vehicle A
	R = 'R', // Partial responsibility for Vehicle A
	C = 'C', // Shared responsibility for Vehicle A and Vehicle B
	NC = 'NC', // Impossible
}

export type WholeSession = CoaSessionInfo & {
	digitalJourney?: DigitalJourney;
};

export const djSessionMock: WholeSession = {
	id: 'FI3g0dNbRJNdNeb8gLaTEK',
	claimId: 'e8ab63d2-5fc4-4a38-9e44-9e3e019bbcd8',
	expiredAt: null,
	clientId: 'conte',
	startedAt: null,
	classifiedAt: '2025-03-16T18:21:26.652Z',
	completedAt: '2025-03-17T18:21:26.652Z',
	mileage: 200000,
	rating: null,
	pointsOfImpact: [PointOfImpact.LEFT_FRONT_CORNER, PointOfImpact.FRONT_CENTER, PointOfImpact.LEFT_SIDE],
	damagedParts: [],
	vin: '12345678901234567',
	enablePayeeJourney: false,
	damagePhotos: [],
	vehiclePhotos: [],
	additionalPhotos: [],
	vehicleConditions: [],
	digitalJourney: {
		firstNamePh: 'Will',
		lastNamePh: 'Mitchell',
		phoneNumberPh: '+3900432552',
		emailPh: 'emdsagds@testemail.com',
		licensePlatePh: 'XX00XX',
		accidentDateTime: '2025-03-10T14:30:00.000Z',
		accidentType: AccidentType.COLLISION_BETWEEN_VEHICLES,
		vandalismSeverity: VandalismSeverity.PARTIAL_THEFT,
		naturalEventType: NaturalEventType.HAIL,
		insuranceType: InsuranceType.COLLISION,
		numInjuredPeople: 1,
		driverPresent: true,
		informationOnOtherDriver: true,
		additionalDetails: 'Rear-end collision at traffic lights.',
		numVehiclesInvolved: 2,
		informationOnOtherVehicle: true,
		claimHandlingDecision: ClaimHandlingDecision.BODY_SHOP,
		cai: CaiEnum.BOTH_SIGNED,
		authoritiesIntervention: AuthoritiesIntervention.LOCAL_POLICE,
		accidentResponsibility: AccidentResponsibility.T,
		vehicleTypePh: VehicleType.CAR,
		pointsOfImpactPh: [PointOfImpact.LEFT_REAR_CORNER, PointOfImpact.RIGHT_REAR_CORNER],
		mileage: 45200,
		airbagDeployed: false,
		isDrivable: true,
		pointsOfImpactGlass: [PointOfImpactGlass.FRONT_WINDOW],
		vehicleCircumstancePh: Circumstance.REAR_END_COLLISION,
		personalCodePh: 'RSSMRA85T10A562S',
		postalCodePh: '20100',
		otherDriverFirstName: 'Jane',
		otherDriverLastName: 'Smith',
		otherDriverPersonalCode: 'SMTJNE90C51F205Z',
		otherVehicleInsurance: 'AXA Assicurazioni',
		otherVehicleLicensePlate: 'XY987ZW',
		otherVehiclePointsOfImpact: [],
		otherVehicleCircumstance: Circumstance.REAR_END_COLLISION,
		otherVehicleType: VehicleType.CAR,
		caiPhoto: [],
		hospitalReportPhoto: [],
		authoritiesPhoto: [],
		injuredPeople: [
			{
				firstName: 'Michael',
				lastName: 'Brown',
				personalCode: 'BRWMHL70A01H501Q',
				role: InjuredRole.DRIVER,
			},
			{
				firstName: 'Mitchell',
				lastName: 'Brown',
				personalCode: 'BRWMHL69A01H420Q',
				role: InjuredRole.PASSENGER,
			},
			{
				firstName: 'Michael',
				lastName: 'Brown',
				personalCode: 'BRWMHL70A01H501Q',
				role: InjuredRole.PASSENGER,
			},
			{
				firstName: 'Mitchell',
				lastName: 'Brown',
				personalCode: 'BRWMHL69A01H420Q',
				role: InjuredRole.PASSENGER,
			},
		],
		accidentLocation: {
			country: 'IT',
			city: 'Agliè',
			province: 'Torino',
			postalCode: '10011',
		},
		bodyShop: {
			name: 'GIOSIA',
			region: 'ABRUZZO',
			province: 'TE',
			city: 'Collarenesco - Giulianova',
			address: 'VIA FONTE NOCE SNC',
			postalCode: '64021',
			phoneNumber: '0858005540',
			email: 'info@autocarrozzeriagiosia.it',
			vatNumber: '01805610670',
		},
	},
};
