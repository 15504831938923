import { map } from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { logEvent } from '@tractable/estimating-local-amplitude-logging';
import { ValidFlag } from '@tractable/estimating-local-constants';
import { calculateTotals, sumLaborAndFixedOrMaterialCost } from '@tractable/estimating-local-estimate-parsing';

import { Claim } from '../../../../../generated/graphql';
import { RowOperationType } from '../../../../../server/graphql/resolvers/claim/estimateDetails';
import { useClientConfigCtx } from '../../../../hooks/useClientConfigCtx';
import { Box } from '../../../shared/Box';
import { ErrorNotification } from '../../../shared/ErrorNotification';
import {
	Decimal,
	EstimateDetails,
	GeneralCost,
	GeneralHours,
	PartRow,
	Section,
	SectionRow,
} from '../../../shared/EstimateDetails';
import { OperationRow } from '../../../shared/EstimateDetails/OperationRow';
import { Currency } from '../../../shared/EstimateDetails/formatCurrency';
import { Text } from '../../../shared/Text';
import { OperationType } from '../../../shared/operationToFill';
import { useOperation } from '../../hooks/useOperation';
import { useUpdateField } from '../../hooks/useUpdateField';
import { useUpdatePartOperation } from '../../hooks/useUpdatePartOperation';
import {
	DetailedPartSection,
	calculateDetailedSection,
	calculatePaintSection,
} from '../../utils/calculate-detailed-section';
import { orderParts } from '../../utils/order-parts';
import { AddGeneralLineSelect } from '../AddGeneralLine';
import { OperationSelector } from '../OperationSelector';

interface Props {
	claim: Claim;
	currency: Currency;
	decimal: Decimal;
	isEditing: boolean;
	collapsedSections: string[];
	toggleSection: (s: string) => void;
	setIsDirty: any;
	validFlags: ValidFlag[];
}

interface AdditionalLineTypes {
	label: string;
	callback: () => void;
}
const getCurrencySymbol = (currency: Currency) => {
	const nf = new Intl.NumberFormat(currency.symbol, currency.options);
	const parts = nf.formatToParts(1234);

	const symbol = parts.find((p) => p.type === 'currency');

	return symbol ? symbol.value : '';
};

const AUX_ELEMENTS_PREFIX_KEY = 'Aux elements prefix';

export const EstimateTable: React.FC<Props> = ({
	claim,
	currency,
	decimal,
	isEditing,
	collapsedSections,
	toggleSection,
	setIsDirty,
	validFlags,
}) => {
	const { t } = useTranslation('EstimatingPortal');

	const {
		updateField,
		updatePaint,
		updateAdditionalCost,
		updateAdditionalHours,
		deleteOperation,
		addCustomOperation,
		updateOperation,
	} = useUpdateField({
		setIsDirty,
	});

	const { removePartOperation, error } = useUpdatePartOperation(setIsDirty, validFlags);
	const { sectionOperationLoading, setOperation } = useOperation(claim, setIsDirty, validFlags);
	const [sectionsToRemove, setSectionsToRemove] = useState<string[]>([]);

	const currencySymbol = getCurrencySymbol(currency);

	useEffect(() => {
		if (error) {
			toast(<ErrorNotification text={t('Unable to delete part. Please try again.')} />, {
				position: 'bottom-left',
				draggable: false,
				hideProgressBar: true,
				autoClose: 4000,
			});
		}
	}, [error]);

	const [showPartIdentifier, setShowPartIdentifier] = useState<boolean>(false);
	const [showPartType, setShowPartType] = useState<boolean>(false);
	const [showPartManufacturerSupplier, setShowPartManufacturerSupplier] = useState<boolean>(false);
	const [showInternalDamage, setShowInternalDamage] = useState<boolean>(false);
	const [showOperationsTable, setShowOperationsTable] = useState<boolean>(false);
	const [hideAllPartPriceData, setHideAllPartPriceData] = useState<boolean>(false);
	const { config } = useClientConfigCtx();

	const showAuxElementsBreakdown = config.ui?.showAuxElementsBreakdown ?? false;

	useEffect(() => {
		setShowPartIdentifier(config.ui?.showPartIdentifier ?? true);
		setShowPartType(config.ui?.showPartType ?? true);
		setShowPartManufacturerSupplier(config.ui?.showPartManufacturerSupplier ?? true);
		setShowOperationsTable(config.ui?.showOperationsTable ?? false);
		setShowInternalDamage(config.ui?.showInternalDamage ?? false);
		setHideAllPartPriceData(config.ui?.hideAllPartPriceData ?? false);
	}, []);

	let auxElementsPrefix = t(AUX_ELEMENTS_PREFIX_KEY);
	if (auxElementsPrefix === AUX_ELEMENTS_PREFIX_KEY) {
		auxElementsPrefix = '';
	} else {
		auxElementsPrefix = auxElementsPrefix + ' ';
	}

	const claimId = claim.id;
	const additionalLines: AdditionalLineTypes[] = [];

	const operations = claim.estimate.operations.map((op, index: number) => {
		const hours = op.labor?.hours ?? 0;
		const rate = op.labor?.rate ?? claim.estimate.info.stripRefitLaborRate;
		const cost = op.cost ?? 0;
		const rowType = op.rowType;

		return {
			//HACK?: because we filter by costs and hours, need to add the index so we can maintain the reference in the original array
			index,
			type: op.type,
			hours,
			//HACK: for backwards compatibility in case rate is missing
			rate,
			cost,
			quantity: op.quantity,
			total: sumLaborAndFixedOrMaterialCost({ cost, labor: { hours, rate }, quantity: op.quantity }),
			description: op.displayDescription ?? t(op.type),
			rowType,
		};
	});

	if (showOperationsTable) {
		additionalLines.push({
			label: 'Add operation',
			callback: () => addCustomOperation(claimId)(RowOperationType.COST),
		});
	} else {
		additionalLines.push({
			label: 'Additional cost line',
			callback: () => addCustomOperation(claimId)(RowOperationType.COST),
		});

		additionalLines.push({
			label: 'Additional hours line',
			callback: () => addCustomOperation(claimId)(RowOperationType.HOURS),
		});
	}

	//HACK: in the bad case the estimate has operations that are not supported in the old view - the total will be wrong (so warn the user)
	const hiddenOperations = operations.filter((x) => x.rowType === RowOperationType.OTHER);
	const isMissingOperations = !showOperationsTable && hiddenOperations.length > 0;

	const totals = calculateTotals(claim.estimate, claim.clientId);

	const paint = calculatePaintSection(claim.estimate.paint, claim.estimate.info);
	const parts = orderParts(claim.estimate.parts);

	const removeSection = (section: any, part: any) => () => {
		logEvent('Edit - delete part', {
			panel: section.belongsTo,
			partId: section.partSelection,
			claimId: claim.id,
		});

		setSectionsToRemove([...sectionsToRemove, part.name]);
		removePartOperation(claim, {
			panel: section.belongsTo,
			operation: part.operation,
			partSelection: section.partSelection,
			partOptions: section.partOptions,
			remove: true,
		})
			.then(() => {
				setSectionsToRemove(sectionsToRemove.filter((s) => s !== part.name));
			})
			.catch(() => {
				setSectionsToRemove(sectionsToRemove.filter((s) => s !== part.name));
			});
	};
	const showMainPartSubtitle = claim.estimate.info.estimateType !== 'SIMPLIFIED';

	return (
		<Box orientation="vertical" gap={8}>
			<EstimateDetails headers={[t('Hours'), t('Cost', { currencySymbol })]}>
				{map(parts, (part, index) => {
					const section: DetailedPartSection = calculateDetailedSection(part, claim.estimate.info);

					return (
						<Section
							key={part.name}
							showMainPartSubtitle={showMainPartSubtitle}
							description={claim.estimate.info.estimateType === 'DETAILED' ? t(section.description) : t(part.name)}
							panel={t(section.belongsTo)}
							isEditing={isEditing && !sectionOperationLoading}
							isDeleting={sectionsToRemove.includes(part.name)}
							{...section.general}
							isOpen={!collapsedSections.includes(part.name)}
							removeSection={removeSection(section, part)}
							toggleSection={() => toggleSection(part.name)}
							operation={() => (
								<OperationSelector
									isEditing={isEditing && !sectionOperationLoading}
									loading={sectionOperationLoading === part.name}
									panel={section.belongsTo}
									operation={part.operation as OperationType}
									partOptions={section.partOptions}
									partSelection={section.partSelection}
									setOperation={setOperation(part.name)}
								/>
							)}
							dataTestId={`part-${index + 1}-info`}
							currency={currency}
						>
							{/* TODO: Could we use a map here? */}
							<PartRow
								description={t('Part cost')}
								isEditable={isEditing && !sectionOperationLoading}
								updateField={updateField(claimId)(part.name)('replacementPartCost')}
								isCostEditable
								displayPartIdentifier={showPartIdentifier ? section.partIdentifier ?? '' : ''}
								partType={section.partType && showPartType !== false ? t(section.partType) : ''}
								partSupplier={section.partSupplier && showPartManufacturerSupplier ? section.partSupplier : ''}
								dataTestId={`part-${index + 1}-part-cost`}
								currency={currency}
								hidePartPrice={hideAllPartPriceData}
								{...section.replacementParts}
							/>
							<SectionRow
								description={t('Strip/refit labour')}
								isEditable={isEditing && !sectionOperationLoading}
								updateField={updateField(claimId)(part.name)('stripRefitLaborHours')}
								dataTestId={`part-${index + 1}-strip-refit`}
								currency={currency}
								decimal={decimal}
								hideCost={hideAllPartPriceData}
								{...section.stripRefit}
							/>
							{part.operation !== 'REPAIR_PAINT' && (
								<SectionRow
									description={t('Panel repair labour')}
									updateField={updateField(claimId)(part.name)('repairLaborHours')}
									isEditable={isEditing && !sectionOperationLoading}
									dataTestId={`part-${index + 1}-panel-repair`}
									currency={currency}
									decimal={decimal}
									hideCost={hideAllPartPriceData}
									{...section.panelRepair}
								/>
							)}
							<SectionRow
								description={t('Paint labour')}
								isEditable={isEditing && !sectionOperationLoading}
								updateField={updateField(claimId)(part.name)('paintLaborHours')}
								dataTestId={`part-${index + 1}-paint-labour`}
								currency={currency}
								decimal={decimal}
								hideCost={hideAllPartPriceData}
								{...section.paintRepair}
							/>
							{/* TODO: Could we have the props be more similar to the structure of "section"? */}
							<SectionRow
								description={t('Paint material')}
								isEditable={isEditing && !sectionOperationLoading}
								updateField={updateField(claimId)(part.name)('paintMaterialCost')}
								isCostEditable
								dataTestId={`part-${index + 1}-paint-material`}
								currency={currency}
								decimal={decimal}
								hideCost={hideAllPartPriceData}
								{...section.paintMaterial}
							/>
							{showAuxElementsBreakdown &&
								part.auxParts?.length > 0 &&
								section.auxParts.parts
									.filter((auxPart) => auxPart.operation == 'REPAIR' || auxPart.operation == 'REPLACE')
									.sort((a, b) => {
										const aName = a.displayName ?? t(a.name);
										const bName = b.displayName ?? t(b.name);
										return aName.localeCompare(bName);
									})
									.map((auxPart, auxIndex) => {
										const name = auxPart.displayName ?? t(auxPart.name);
										const key = `part-${index + 1}-aux-parts-${auxIndex + 1}`;
										return (
											<SectionRow
												key={key}
												description={`${auxElementsPrefix}${name}`}
												isEditable={false}
												dataTestId={key}
												currency={currency}
												decimal={decimal}
												hideCost={hideAllPartPriceData}
												{...auxPart}
											/>
										);
									})}
							{!showAuxElementsBreakdown && section.auxParts?.cost !== 0 && (
								<SectionRow
									description={t('Auxiliary elements')}
									isEditable={false}
									dataTestId={`part-${index + 1}-aux-parts`}
									currency={currency}
									decimal={decimal}
									hideCost={hideAllPartPriceData}
									{...section.auxParts}
								/>
							)}
						</Section>
					);
				})}
				<Section
					description={t(paint.total.description)}
					showMainPartSubtitle={showMainPartSubtitle}
					cost={paint.total.cost}
					isOpen={!collapsedSections.includes(paint.total.id)}
					toggleSection={() => toggleSection(paint.total.id)}
					currency={currency}
				>
					<SectionRow
						description={t(paint.paintLabor.description)}
						isEditable={isEditing && !sectionOperationLoading}
						updateField={updatePaint(claimId)}
						hours={paint.paintLabor.hours}
						cost={paint.paintLabor.cost}
						currency={currency}
						decimal={decimal}
						hideCost={hideAllPartPriceData}
					/>
					<SectionRow
						description={t(paint.paintMaterial.description)}
						isEditable={isEditing && !sectionOperationLoading}
						updateField={updatePaint(claimId)}
						isCostEditable
						cost={paint.paintMaterial.cost}
						currency={currency}
						hideCost={hideAllPartPriceData}
					/>
				</Section>
				{!showOperationsTable &&
					operations.map((operation, opIndex) => (
						<>
							{operation.rowType === RowOperationType.COST && (
								<GeneralCost
									key={`${operation.index}-${operation.description}-${opIndex}`}
									isEditable={isEditing && !sectionOperationLoading}
									description={operation.description}
									updateField={updateAdditionalCost(claimId)(operation.index)}
									removeSection={isEditing && deleteOperation(claimId, operation.index)}
									cost={operation.total}
									currency={currency}
									dataTestId={`general-line-ac-${operation.index}`}
								/>
							)}
							{operation.rowType === RowOperationType.HOURS && (
								<GeneralHours
									key={`${operation.index}-${operation.description}-${opIndex}`}
									isEditable={isEditing && !sectionOperationLoading}
									description={operation.description}
									updateField={updateAdditionalHours(claimId)(operation.index)}
									removeSection={isEditing && deleteOperation(claimId, operation.index)}
									hours={operation.hours}
									dataTestId={`general-line-ah-${operation.index}`}
									isCostEditable
									cost={operation.total}
									currency={currency}
									decimal={decimal}
								/>
							)}
						</>
					))}
				{showInternalDamage && (
					<Section
						description={t(`Internal damage estimate`)}
						showMainPartSubtitle={showMainPartSubtitle}
						cost={totals.additionalPartsDisplayCostTotal}
						isOpen
						toggleSection={() => null}
						currency={currency}
					/>
				)}
			</EstimateDetails>
			{isMissingOperations && (
				<Text typography="micro" color="#D25459" data-test-id={`operations-missing-error-text`}>
					{t('Some operations have been hidden from the estimate.')}
				</Text>
			)}
			{showOperationsTable && (
				<EstimateDetails
					headers={[
						t('Cost', { currencySymbol }),
						t('Hours'),
						t('Rate'),
						t('Qty'),
						t('Total Cost', { currencySymbol }),
					]}
					widthsOverrides={{
						3: '40px',
						4: '120px',
					}}
				>
					<Section
						description="Additional Operations"
						isOpen={!collapsedSections.includes('additional-operations')}
						toggleSection={() => toggleSection('additional-operations')}
						showMainPartSubtitle={false}
						cost={totals.operationsTotal}
						currency={currency}
					>
						{operations.map((operation) => {
							return (
								<OperationRow
									key={`operation-${operation.index}`}
									removeSection={isEditing && deleteOperation(claimId, operation.index)}
									updateField={updateOperation(claimId)(operation.index)}
									dataTestId={`operation-line-${operation.index}`}
									isEditable={isEditing && !sectionOperationLoading}
									isDescriptionEditable={operation.type === 'CUSTOM'}
									{...operation}
									currency={currency}
									hideCost={hideAllPartPriceData}
								/>
							);
						})}
					</Section>
				</EstimateDetails>
			)}
			{isEditing && !sectionOperationLoading && (
				<AddGeneralLineSelect text={t('New line')}>
					{additionalLines.map((al) => (
						<Text
							key={al.label}
							typography="standard"
							hover="background: #E2DEFF"
							cursor="pointer"
							p="12px 16px 12px 16px"
							onClick={() => al.callback()}
						>
							{t(al.label)}
						</Text>
					))}
				</AddGeneralLineSelect>
			)}
		</Box>
	);
};
