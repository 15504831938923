import { useApolloClient } from '@apollo/client';
import { Button, Intent } from '@blueprintjs/core';
import createDecorator from 'final-form-focus';
import gql from 'graphql-tag';
import * as React from 'react';
import { Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { logEvent } from '@tractable/estimating-local-amplitude-logging';

import { useClientConfigCtx } from '../../../hooks/useClientConfigCtx';
import useClientTranslations from '../../../hooks/useClientTranslations';
import { Header, PageLayout, Widget } from '../../shared';
import { NavIcon } from '../../shared/Navbar';
import { Text } from '../../shared/Primitives';
import { fields, transform } from '../Create/Fields';
import { FieldType } from '../Create/Fields/FieldType';
import { fixDateFromDate } from '../Create/Fields/date';
import { requestErrorHandler } from '../Create/requestErrorHandler';

interface CreateProps {
	mutate: (props: any) => any;
}

const focusOnError = createDecorator();

const Wrapper = styled.div`
	display: block;
	margin: 40px auto;
	max-width: 800px;
	min-width: 400px;
`;

const GET_DIGITAL_JOURNEY_LINK = gql`
	query getDJLink($id: String, $phone: String, $digitalJourney: DigitalJourneyCreateDto) {
		webappLink(id: $id, phone: $phone, digitalJourney: $digitalJourney) {
			link
		}
	}
`;

const WidgetForm = Widget(Form);

const CreateDigitalJourney: React.FC<CreateProps> = ({ mutate }) => {
	const client = useApolloClient();
	const navigate = useNavigate();
	const { config } = useClientConfigCtx();
	const { t } = useClientTranslations();

	const clientName = config.clientId;
	const createClaimFields = config?.ui?.digitalJourneyClaimFields;

	const fieldsToUse = [...createClaimFields];

	return (
		<PageLayout
			navigationIcon={NavIcon.BACK}
			navigationHeader=""
			navigationOnClick={() => navigate('/')}
			header={() => <Header title={t('Create new Digital Journey request')} />}
		>
			<Wrapper>
				<WidgetForm
					gridArea="f2"
					padding="3rem"
					onSubmit={(values: any) => {
						const transformedValues = transform(values, fieldsToUse as FieldType[], []);
						const digitalJourney = {
							firstNamePh: transformedValues.policyholderFirstname || '',
							lastNamePh: transformedValues.policyholderLastname || '',
							licensePlatePh: transformedValues.registration || '',
							phoneNumberPh: transformedValues.phone,
							emailPh: transformedValues.policyholderEmail || '',
						};
						const date = new Date();
						const clientClaimId = `DJ-${date.getTime()}`;
						return mutate({
							variables: {
								data: { clientClaimId, dateOfAccident: fixDateFromDate(date) },
							},
						})
							.then(
								({
									data: {
										createClaim: { id },
									},
								}: any) => {
									logEvent('Submit Digital Journey request', { claimId: id });
									client
										.query({
											query: GET_DIGITAL_JOURNEY_LINK,
											variables: { id, phone: transformedValues.phone, digitalJourney },
										})
										.then(
											({
												data: {
													webappLink: { link },
												},
											}: any) => {
												if (link) navigate('/hub');
											}
										)
										.catch((e: any) => requestErrorHandler(e));
								}
							)
							.catch((e: any) => requestErrorHandler(e));
					}}
					decorators={[focusOnError]}
				>
					{({ handleSubmit, submitting, submitErrors }: any) => (
						<form onSubmit={handleSubmit}>
							{fieldsToUse.map((field: FieldType) => {
								const Field = fields[field].component;
								return (
									<Field
										key={field}
										brands={[]}
										regions={[]}
										clientName={clientName}
										dateFormat={config.locale.dateFormat}
										policies={config.policies}
										currencySymbol=""
										estimateType=""
										isRequired={field === FieldType.PH_PHONE}
									/>
								);
							})}
							<Button
								id="formSubmit"
								type="submit"
								disabled={submitting}
								intent={Intent.PRIMARY}
								text={t('Submit')}
								data-test-id="submit-claim-button"
								style={{ backgroundColor: '#3B25C4' }}
							/>
							{submitErrors && submitErrors.ooh && (
								<Text fontSize="14px" color="#c23030" my={2}>
									{t(submitErrors.ooh)}
								</Text>
							)}
							{submitErrors && submitErrors.invalidRequest && (
								<Text fontSize="14px" color="#c23030" my={2}>
									{t(submitErrors.invalidRequest)}
								</Text>
							)}
							{submitErrors && submitErrors.unexpectedServerError && (
								<Text fontSize="14px" color="#c23030" my={2}>
									{t(submitErrors.unexpectedServerError)}
								</Text>
							)}
						</form>
					)}
				</WidgetForm>
			</Wrapper>
		</PageLayout>
	);
};

export { CreateDigitalJourney };
